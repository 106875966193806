import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.other.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-other-info',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.other.info/view.scss */
.row {
  margin: 0;
}

td {
  align-content: center !important;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.align-items-normal {
  align-items: normal;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudExternalOtherInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.id) return this.back();
        await this.load();
        this.loaded = true;
        await this.service.render();
    }

    public async call(api, data = {}) {
        const body = {
            id: this.info.id,
        };
        const category = WizRoute.segment.category;
        if (["op", "idp"].includes(category)) body.client_type = "provider";
        else body.client_type = "service";
        return await wiz.call(api, { ...body, ...data });
    }

    public otherInfo = [];
    public async load() {
        this.otherInfo = {};
        await this.service.render();
        const { code, data } = await this.call("load");
        if (code != 200) return;
        this.otherInfo = data;
        await this.service.render();
    }

    public async save() {
        const otherInfo = this.otherInfo.map(it => {
            if (!it.key) return null;
            if (!it.user) return null;
            return {
                key: it.key,
                user: it.user.id,
            };
        }).filter(it => it);
        const { code } = await this.call("save", { otherInfo });
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("정보 변경 성공");
        this.load();
    }

    public modal = null;
    public modalText = "";
    public userList = [];

    public showModal(data) {
        this.userList = [];
        this.modalText = "";
        this.modal = data;
        this.service.render();
    }

    public disabled(user) {
        if (!this.otherInfo[this.modal].user) return false;
        if (this.otherInfo[this.modal].user.id === user.id) return true;
        return false;
    }

    public search: any = {
        page: 1,
    };
    public pagenation: any = {
        end: 1,
        start: 1,
    };
    public async searchUser(page = 1) {
        if (this.modalText.replace(/\s/g, "").length < 2) return;
        this.search.page = page;
        this.userList = [];
        await this.service.render();
        const body = {
            page: this.search.page,
            text: this.modalText,
        };
        const { code, data } = await this.call("search_user", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.userList = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async select(user) {
        if (this.disabled(user)) return;
        this.otherInfo[this.modal].user = user;
        this.showModal(null);
    }
}

export default PortalKeycloudExternalOtherInfoComponent;