import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.pre');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-pre',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.pre/view.scss */
pre {
  white-space: pre-wrap;
  word-break: break-all;
}

.position-relative:hover .btn {
  display: block;
}

.btn {
  display: none;
  top: 10px;
  right: 10px;
}

.copy-msg {
  top: 10px;
  right: 40px;
}`],
})
export class ComponentPreComponent implements OnInit {
    @Input() text: any;
    @Input() TF: any = false;

    constructor(@Inject( Service)         public service: Service,    ) { }
    public async ngOnInit() {
        await this.service.init();
    }

    public copied = null;
    public copy(value) {
        let text = value;
        if (typeof value !== "string") text = JSON.stringify(value);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.style.opacity = 0;
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999);
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('복사 실패', err);
            }
            textArea.setSelectionRange(0, 0);
            document.body.removeChild(textArea);
        }
        if (this.copied) {
            clearTimeout(this.copied);
            this.copied = null;
        }
        this.copied = setTimeout(() => {
            this.copied = null;
            this.service.render();
        }, 1500);
        this.service.render();
    }
}

export default ComponentPreComponent;