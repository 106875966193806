import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.question');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-question',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.question/view.scss */
.question {
  cursor: pointer;
  background-color: #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
}

[data-tooltip]::before {
  position: absolute !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  transform: translate(-50%, calc(-100% - 12px)) !important;
}

.no-fixed[data-tooltip]::before {
  position: absolute !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  transform: translate(-50%, calc(-100% - 12px)) !important;
}`],
})
export class ComponentQuestionComponent implements OnInit {
    @Input() className = "";
    @Input() text = "";
    @Input() copy = true;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public onCopy() {
        if (!this.copy) return;
        navigator.clipboard.writeText(this.text);
        this.service.toast.success("Copied!");
    }
}

export default ComponentQuestionComponent;