import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.attributes');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-attributes',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.attributes/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudAdminAttributesComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public list: any = [];
    public newItem: any = null;

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public isDisabled(key) {
        return ['email', 'preferred_username', 'sub', 'givenName', 'sn'].includes(key);
    }

    public editDisabled(key) {
        return ['preferred_username', 'sub'].includes(key);
    }

    public modal = {
        mode: null,
        data: null,
    };

    public closeModal() {
        this.modal = {
            mode: null,
            data: null,
        };
        this.service.render();
    }

    public defaultOption() {
        return {
            maxlength: 255,
            multiple: false,
            all: true,
            oidc: {
                access_token: false,
                id_token: true,
                userinfo: true,
            },
            default_value: null,
        };
    }

    public showModal(mode, data = this.defaultOption()) {
        this.modal.mode = mode;
        this.modal.data = this.service.copy(data);
        this.service.render();
    }

    public async remove(item: any) {
        if (this.isDisabled(item.key)) return;
        const res = await this.service.alert.show({
            title: "DELETE",
            message: `정말 "${item.key}" 속성을 삭제하시겠습니까?`,
            action: "delete",
            cancel: "cancel",
        });
        if (!res) return;
        await wiz.call("delete", item);
        this.load(this.search.page);
    }

    public async update() {
        const body = this.service.copy(this.modal.data);
        if (body.key.replace(/\s/g, "").length === 0) return await this.service.error("name을 반드시 입력해주세요.");
        if (!/^[A-Za-z0-9_]+$/.test(body.key)) return await this.service.error("name은 알파벳, _, 숫자로 이루어져야 합니다.");
        if (body.default_value && body.default_value.length === 0) body.default_value = null;

        await this.service.loading.show();
        const { code, data } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("저장 실패!");
        await this.service.success("저장 성공!");
        this.closeModal();
        await this.load();
    }
}

export default PortalKeycloudAdminAttributesComponent;