import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.rp.owners');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-rp-owners',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.rp.owners/view.scss */
.row {
  margin: 0;
}

td {
  align-content: center !important;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.align-items-normal {
  align-items: normal;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudExternalRpOwnersComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.id) return this.back();
        await this.load();
        this.loaded = true;
        await this.service.render();
    }

    public list = [];

    public async load() {
        this.list = [];
        await this.service.render();
        const body = { client_id: this.info.src.client_id };
        const { code, data } = await wiz.call("load", body);
        if (code != 200) return;
        this.list = data;
        await this.service.render();
    }

    public text = "";
    public match(user) {
        const t = this.text.toLowerCase();
        const { first_name, last_name, email } = user;
        if (first_name.toLowerCase().includes(t)) return true;
        if (last_name.toLowerCase().includes(t)) return true;
        if (email.toLowerCase().includes(t)) return true;
        return false;
    }

    public async add(user) {
        const res = await this.service.alert.show({
            title: `관리자 권한 추가`,
            status: "success",
            message: `정말 "${user.email}" 사용자에게 관리자 권한을 부여하시겠습니까?`,
            action: "apply",
            actionBtn: "success",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("add", { client_id: this.info.src.client_id, user_id: user.id });
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("관리자 권한 추가 성공!");
        this.load();
        if (this.modal) this.searchUser();
    }

    public async change(user) {
        const res = await this.service.alert.show({
            title: `OWNER 변경`,
            status: "success",
            message: `OWNER를 정말 "${user.email}" 사용자로 변경하시겠습니까?`,
            action: "apply",
            actionBtn: "success",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("change", { client_id: this.info.src.client_id, user_id: user.id });
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("OWNER 변경 성공!");
        this.load();
        if (this.modal) this.searchUser();
    }

    public async remove(user) {
        if (this.service.auth.session.id === user.id) return this.service.error("자기 자신의 권한은 수정할 수 없습니다.");
        const res = await this.service.alert.show({
            title: `관리자 권한 제거`,
            status: "error",
            message: `정말 "${user.email}" 사용자의 관리자 권한을 제거하시겠습니까?`,
            action: "delete",
            actionBtn: "error",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("remove", { client_id: this.info.src.client_id, user_id: user.id });
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("관리자 권한 제거 성공!");
        this.load();
    }

    public back() {
        this.service.href(this._url(`/external/oidc/rp`));
    }

    public modal = null;
    public modalText = "";
    public userList = [];
    public modalTitle() {
        const map = {
            owner: "Owner 변경하기",
            add: "관리자 권한 추가하기",
        };
        return map[this.modal];
    }
    public showModal(act) {
        this.userList = [];
        this.modalText = "";
        this.modal = act;
        this.service.render();
    }

    public disabled(user) {
        if (this.info.owner.id === user.id) return true;
        if (this.modal === "add") {
            const el = this.list.find(it => it.id === user.id);
            if (el) return true;
            return false;
        }
        return false;
    }

    public search: any = {
        page: 1,
    };
    public pagenation: any = {
        end: 1,
        start: 1,
    };
    public async searchUser(page = 1) {
        if (this.modalText.replace(/\s/g, "").length < 2) return;
        this.search.page = page;
        this.userList = [];
        await this.service.render();
        const body = {
            page: this.search.page,
            client_id: this.info.src.client_id,
            text: this.modalText,
        };
        const { code, data } = await wiz.call("search_user", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.userList = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async select(user) {
        if (this.disabled(user)) return;
        if (this.modal === "add") {
            await this.add(user);
        }
        else {
            await this.change(user);
            location.reload();
        }
    }

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }
}

export default PortalKeycloudExternalRpOwnersComponent;