import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.sp');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { SAMLUtil } from "src/libs/portal/keycloud/util/saml";

@Component({
    selector: 'wiz-portal-keycloud-external-sp',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.sp/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.file-custom {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dddddd;
}
.file-custom strong {
  line-height: 32px;
  border-left: 1px solid #dddddd;
}

.v-center > * {
  display: inline-block;
  vertical-align: middle;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudExternalSpComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any;

    public loaded: boolean = false;
    public samlUtil: SAMLUtil = new SAMLUtil();
    public monacoEditor: any;
    public O = Object;

    public monacoopts: any = {
        value: '',
        language: 'html',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        if (this.info.src.entityid) {
            await this.loadTenants();
            await this.mdParse();
        }
        this.loaded = true;
        await this.service.render();
    }

    public tenantList = [];
    public async loadTenants() {
        const { code, data } = await wiz.call("tenants");
        if (code !== 200) return await this.service.error("Error");
        this.tenantList = data;
        if (this.info.extra.tenants) {
            this.tenantList.forEach(tenant => {
                if (this.info.extra.tenants.includes(tenant.id)) tenant.checked = true;
            });
        }
        await this.service.render();
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        if (this.info.src && this.info.src.entityid) {
            this.monacoEditor.updateOptions({ readOnly: true });
            const el = document.querySelector("nu-monaco-editor");
            el.style.height = "400px";
            el.querySelector(".monaco-editor").style.height = "400px";
        }
    }

    public file = null;
    public async onFileSelect(e) {
        const files = e.target.files;
        if (files.length === 0 || !files) {
            this.file = null;
            return;
        }
        const text = await files[0].text();
        if (!this.isValidXML(text)) {
            await this.service.error("XML 형식의 파일이 아닙니다.");
            this.file = null;
            await this.service.render();
            return;
        }
        this.file = files[0];
        this.service.render();
    }

    public isValidXML(text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "application/xml");
        const err = doc.querySelector("parsererror");
        if (err) return false;
        return true;
    }

    public disabled() {
        if (!this.newItem.name) return;
        if (this.newItem.name.replace(/\s/g, "").length === 0) return;
        if (this.direct) {
            if (!this.isValidXML(this.newItem.text)) return true;
            return false;
        }
        else {
            if (!this.file) return true;
            return false;
        }
    }

    public newItem = {
        name: "",
        text: "",
        url: "",
        status: false,
    };

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public async create() {
        if (!this.newItem.name) return;
        let res;
        if (this.disabled()) return;
        await this.service.loading.show();
        if (this.direct) {
            res = await wiz.call("enroll", {
                name: this.newItem.name,
                xml: this.newItem.text,
            });
        }
        else {
            const body = new FormData();
            body.append("file[]", this.file);
            body.append("name", this.newItem.name);
            res = await wiz.call("upload", {}, { body, headers: undefined });
        }
        await this.service.loading.hide();
        const { code, data } = res;
        if (code !== 200) return await this.service.error("Invalid metadata format");
        location.href = this._url(`/external/saml/sp/${data}/info`);
    }

    public md = null;

    public parseEntityDescriptor(idp, namespaces) {
        const md = namespaces["urn:oasis:names:tc:SAML:2.0:metadata"];
        const ds = namespaces["http://www.w3.org/2000/09/xmldsig#"];

        const obj = {
            entityID: idp.getAttribute("entityID"),
            cert: [],
            sls: [],
            acs: [],
            attrs: [],
            org: {},
            contact: {},
        };

        const certs = idp.getElementsByTagName(`${md}:KeyDescriptor`);
        Array.from(certs).forEach(cert => {
            const use = cert.getAttribute("use");
            const tmp = cert.getElementsByTagName(`${ds}:X509Certificate`);
            if (tmp.length === 0) return;
            obj.cert.push({ use, text: tmp[0].textContent });
        })

        const sls = idp.getElementsByTagName(`${md}:SingleLogoutService`);
        Array.from(sls).forEach(it => {
            const binding = it.getAttribute("Binding");
            const location = it.getAttribute("Location");
            obj.sls.push({ binding, location });
        });

        const acs = idp.getElementsByTagName(`${md}:AssertionConsumerService`);
        Array.from(acs).forEach(it => {
            const binding = it.getAttribute("Binding");
            const location = it.getAttribute("Location");
            obj.acs.push({ binding, location });
        });

        let acs2 = idp.getElementsByTagName(`${md}:AttributeConsumingService`);
        if (acs2.length > 0) {
            acs2 = acs2[0];
            const get = (tag) => {
                const tmp = acs2.getElementsByTagName(`${md}:${tag}`);
                if (tmp.length === 0) return null;
                return tmp[0].textContent;
            }
            obj.ServiceName = get("ServiceName");
            obj.ServiceDescription = get("ServiceDescription");
            const attrs = acs2.getElementsByTagName(`${md}:RequestedAttribute`);
            obj.attrs = Array.from(attrs).map((attr) => {
                const res = {};
                attr.getAttributeNames().forEach(an => {
                    res[an] = attr.getAttribute(an);
                })
                return res;
            });
        }
        Array.from(acs).forEach(it => {
            const binding = it.getAttribute("Binding");
            const location = it.getAttribute("Location");
            obj.acs.push({ binding, location });
        });

        const org = idp.getElementsByTagName(`${md}:Organization`);
        if (org.length > 0) {
            Array.from(org[0].children).forEach(it => {
                obj.org[it.tagName.split(":")[1]] = it.textContent;
            });
        }

        const contact = idp.getElementsByTagName(`${md}:ContactPerson`);
        if (contact.length > 0) {
            Array.from(contact[0].children).forEach(it => {
                obj.contact[it.tagName.split(":")[1]] = it.textContent;
            });
        }
        return obj;
    }

    public async mdParse() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.info.src.metadata, "application/xml");
        const root = doc.firstElementChild;
        if (!root.tagName.endsWith(":EntityDescriptor")) return await this.service.error("Invalid Metadata");

        // 1. get namespaces
        const namespaces = {};
        root.getAttributeNames().forEach(attr => {
            const val = root.getAttribute(attr);
            if (attr.startsWith("xmlns:")) {
                const ns = attr.slice("xmlns:".length);
                namespaces[val] = ns;
            }
        });

        // 2. make sp info
        this.md = this.parseEntityDescriptor(root, namespaces);
        await this.service.render();
    }

    public async update() {
        const body = this.service.copy(this.info);
        const tenants = this.tenantList.reduce((acc, tenant) => {
            if (!tenant.checked) return acc;
            acc.push(tenant.id);
            return acc;
        }, []);
        body.extra.tenants = tenants;
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("ERROR");
        return await this.service.success("저장 성공");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete SAML SP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", this.info);
        this.back();
    }

    public back() {
        this.service.href(this._url("/external/saml/sp"));
    }
}

export default PortalKeycloudExternalSpComponent;