import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.agreement');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-agreement',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.agreement/view.scss */
.btn-area button {
  width: 50%;
  height: 50px;
  margin: 0 15px;
  font-size: 24px;
}

label {
  line-height: 44px;
  font-size: 24px;
}
label input[type=checkbox] {
  width: 24px;
  height: 24px;
}
label > * {
  vertical-align: middle;
}

.ck-content {
  padding: 32px !important;
  max-width: 860px;
  margin: 0 auto;
  height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #000;
}
.ck-content h2 {
  margin-top: 0 !important;
}

.content {
  padding-bottom: 40px;
}

.content-inner {
  max-width: 860px;
  margin: 0 auto;
}

.storybook {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1440px;
  width: 90vw;
  margin: 0px auto;
  height: calc(100% - 24px);
  margin-top: 24px;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .storybook {
    width: 92vw;
  }
}

.storybook::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/images/background.jpg");
}

.content-dark {
  background-color: #2b2c2e !important;
  color: #fff;
}

.content-light {
  background-color: #E9E8EE;
}

.row .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.row .detail {
  background-color: #EAF0FB;
  box-shadow: none;
  font-family: SUIT;
  border: none;
  margin: 0.5rem 0;
  height: calc(100% - 0.5rem);
}
.row .detail .key {
  color: #8492AE;
  height: 20px;
}
.row .detail .value {
  white-space: unset;
  word-break: normal;
  border-radius: 0;
}

.info {
  display: flex;
  color: white;
  height: 140px;
  margin: auto;
  align-items: center;
}
@media (max-width: 768px) {
  .info {
    display: block;
    height: auto;
  }
}
.info .group {
  cursor: pointer;
  margin-right: 64px;
  margin-bottom: 24px;
}
.info .group .col-auto {
  display: flex;
  align-items: end;
}
.info .group h2 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 0px;
  padding: 0;
  cursor: pointer;
}
.info .group img {
  width: 40px;
  height: 40px;
}
.info h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.info span {
  color: #e9e8ee;
  font-family: SUIT;
  font-weight: normal;
  font-size: 16px;
}

.table-card {
  background-color: transparent;
}
.table-card table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 8px;
}
.table-card table th,
.table-card table td {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  text-align: left;
}
.table-card table th.w-1,
.table-card table td.w-1 {
  width: 140px !important;
}
.table-card table td[data-title]:hover::after {
  content: attr(data-title);
  position: fixed;
  background-color: #333;
  color: #eee;
  border-radius: 4px;
  padding: 0 7px;
  transform: translate(-100%, 0);
  z-index: 4000;
}
.table-card table thead th {
  padding-bottom: 10px;
  border-bottom: solid 2px #aab5ca;
  background-color: transparent;
}
.table-card table thead th input.form-check {
  width: 20px;
  height: 20px;
  margin: 0;
  display: inline-block;
}
.table-card table tr {
  background-color: white;
}
.table-card table tr.isadmin {
  background-color: #ffe4d8;
}
.table-card table tr.ishover:hover {
  background-color: #d9e6ff;
  color: var(--c1);
  cursor: pointer;
}
.table-card table tr.ishover:hover td {
  border-top-color: var(--c1);
  border-bottom-color: var(--c1);
}
.table-card table tr.ishover:hover td:first-child {
  border-left-color: var(--c1);
}
.table-card table tr.ishover:hover td:last-child {
  border-right-color: var(--c1);
}
.table-card table tr.active {
  background-color: #d9e6ff;
  color: var(--c1);
  cursor: pointer;
}
.table-card table tr.active td {
  border-top-color: var(--c1);
  border-bottom-color: var(--c1);
}
.table-card table tr.active td:first-child {
  border-left-color: var(--c1);
}
.table-card table tr.active td:last-child {
  border-right-color: var(--c1);
}
.table-card table tr td {
  position: relative;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  line-height: 44px;
}
.table-card table tr td input.form-check {
  display: block;
  width: 18px;
  height: 18px;
  margin: 7px auto 0;
}
.table-card table tr td.actions {
  padding: 8px;
}
.table-card table tr td.actions button + button {
  margin-left: 8px;
}
.table-card table tr td:first-child {
  border-left: 2px solid white;
}
.table-card table tr td:last-child {
  border-right: 2px solid white;
}
.table-card table tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-card table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-card table tr .display_name {
  position: relative !important;
  inset: 0;
  background-color: transparent;
}

.round-0 {
  border-radius: 0 !important;
}

.table-card-attributes {
  background-color: transparent;
}
.table-card-attributes table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.table-card-attributes table thead th {
  padding-bottom: 10px;
  border-bottom: solid 2px #aab5ca;
  background-color: transparent;
  text-transform: uppercase;
  text-align: left;
}
.table-card-attributes table tr {
  background-color: white;
}
.table-card-attributes table tr.ishover:hover {
  background-color: #d9e6ff;
  color: var(--c1);
  cursor: pointer;
}
.table-card-attributes table tr.ishover:hover td {
  border-top-color: var(--c1);
  border-bottom-color: var(--c1);
}
.table-card-attributes table tr.ishover:hover td:first-child {
  border-left-color: var(--c1);
}
.table-card-attributes table tr.ishover:hover td:last-child {
  border-right-color: var(--c1);
}
.table-card-attributes table tr.active {
  background-color: #d9e6ff;
  color: var(--c1);
  cursor: pointer;
}
.table-card-attributes table tr.active td {
  border-top-color: var(--c1);
  border-bottom-color: var(--c1);
}
.table-card-attributes table tr.active td:first-child {
  border-left-color: var(--c1);
}
.table-card-attributes table tr.active td:last-child {
  border-right-color: var(--c1);
}
.table-card-attributes table tr td {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  line-height: 32px;
  text-align: left;
  vertical-align: middle;
}
.table-card-attributes table tr td input.form-check {
  display: block;
  width: 18px;
  height: 18px;
  margin: 7px auto 0;
}
.table-card-attributes table tr td.actions {
  padding: 8px;
}
.table-card-attributes table tr td.actions button + button {
  margin-left: 8px;
}
.table-card-attributes table tr td.attributes-column .btn-common {
  height: 32px;
  padding: 0 6px;
  border-radius: 6px;
}
.table-card-attributes table tr td:first-child {
  border-left: 2px solid white;
}
.table-card-attributes table tr td:last-child {
  border-right: 2px solid white;
}
.table-card-attributes table tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-card-attributes table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-card-attributes table tr .display_name {
  position: relative !important;
  inset: 0;
  background-color: transparent;
}`],
})
export class PageAgreementComponent implements OnInit {
    private check = {
        user_agreement: false,
        privacy_policy: false,
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
    }

    public async disagree() {
        await wiz.call("disagree");
        location.href = "/auth/logout";
    }

    public async agree() {
        if (!this.check.user_agreement) {
            await this.service.error(`개인정보수집이용에 대해 동의해주세요.`);
            document.querySelector("#check-a").focus();
            return;
        }
        if (!this.check.privacy_policy) {
            await this.service.error(`이용약관에 대해 동의해주세요.`);
            document.querySelector("#check-p").focus();
            return;
        }
        const { code } = await wiz.call("agree");
        if (code !== 200) return await this.service.error("SERVER ERROR. Please contact system administrator.");
        location.href = "/mypage";
    }
}

export default PageAgreementComponent;