import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.discovery');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-discovery',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.discovery/view.scss */
.wrapper {
  background-image: url(/assets/images/login.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container.half {
  max-width: 480px;
}

.wrapper-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.circle {
  background-color: #fff;
  border: 3px solid var(--wc-blue);
  width: 140px;
  height: 140px;
  top: -90px;
  left: calc(50% - 70px);
  border-radius: 70%;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
  z-index: 5;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle .logo {
  width: 100%;
  max-width: 80px;
  margin: auto;
}
.circle .logo > a {
  width: 80px;
}
.circle .logo img {
  width: 100%;
}

.form {
  padding: 40px 24px 52px;
  z-index: 2;
  color: #000000;
  background-color: white;
  border-radius: 16px;
  position: relative;
}
.form .box-login {
  display: flex;
}
.form input {
  border: 1px solid #000;
}
.form input:disabled {
  border-right-color: #000;
  border-left-color: #000;
  color: var(--wc-gray);
  text-align: center;
}
.form input:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-color: #000;
  font-family: "main-r";
}
.form input:not(:last-child) {
  margin-right: -1px;
}
.form input:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-color: #000;
  font-family: "main-r";
}

.wrapper .form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 15vh 0;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 768px) {
  .container {
    padding: 10vh 16px;
  }
}

.box-login {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
}
@media (max-width: 768px) {
  .box-login {
    width: 85%;
  }
}

.form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-width: 1px;
  background-color: rgb(255, 255, 255);
  display: block;
  font-size: 18px;
  color: #2b2929;
  transition-duration: 0.25s;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  box-sizing: border-box;
}

.form input:hover {
  background-color: rgb(255, 255, 255);
}

.form input:focus {
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: #000000;
  border-color: #6d8df6;
}

.form input::placeholder {
  color: #ddd;
}

.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  transition-duration: 0.25s;
  position: relative;
  width: 70%;
  margin: auto;
  padding: 18px 19px 19px;
  box-sizing: border-box;
  font-family: "main-b";
}
@media (max-width: 768px) {
  .form button {
    width: 80%;
  }
}

.addition-info {
  width: 70%;
  margin: auto;
  font-size: 14px;
  display: block;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .addition-info {
    width: 80%;
  }
}
.addition-info a {
  color: var(--wc-text);
  cursor: pointer;
}
.addition-info a:hover {
  color: #6380de;
}

.form button:hover {
  color: var(--wc-blue);
  border: 1px solid var(--wc-blue);
  background-color: rgba(108, 141, 246, 0.2);
}

.hint {
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  color: var(--wc-text);
  background-color: var(--wc-light);
}

.single-sign-on {
  text-align: center;
}
.single-sign-on .overflow-y-auto {
  max-height: 300px;
}
.single-sign-on .card {
  max-width: 320px;
  margin: 12px auto;
  cursor: pointer;
}

.rounded-start,
.rounded-end {
  --tblr-border-radius: 10px;
}

.login-box {
  margin-top: 40px;
}
.login-box input {
  padding: 18px 19px;
}`],
})
export class PageDiscoveryComponent implements OnInit {
    public loaded = false;
    public token = null;
    public text = "";

    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/authenticate");
        const res = await this.load();
        if (!res) {
            return location.href = "/authenticate";
        }
        const { rows, id, token } = res;
        this.id = id;
        this.list = rows;
        this.token = token;
        this.loaded = true;
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return false;
        return data;
    }

    public async sso(idp) {
        let params = {
            key: this.id,
            entityid: idp.entityID,
            authnContextRef: "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
        };
        if (this.token) params.token = this.token;
        params = new URLSearchParams(params);
        return location.href = `/sp/login?${params.toString()}`;
    }

    public filter(idp) {
        const text = this.text.toLowerCase();
        if (idp.entityID.toLowerCase().includes(text)) return true;
        if (idp.DisplayName && idp.DisplayName.toLowerCase().includes(text)) return true;
        return false;
    }
}

export default PageDiscoveryComponent;