import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.loading');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-loading',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.loading/view.scss */
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #16a085;
  animation: spin 2.2s linear infinite;
  z-index: 5011;
}
.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 134px;
  height: 134px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  animation: spin-reverse 0.9s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #bb9100;
  animation: spin 1.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}`],
})
export class ComponentLoadingComponent implements OnInit {

    public async ngOnInit() {}
}

export default ComponentLoadingComponent;