import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.nav/view.scss */
.navbar {
  display: block;
  background: #fff;
  padding: 0;
}
.navbar .section {
  display: flex;
  align-items: center;
}
.navbar .section:last-child {
  border-top: 1px solid var(--wc-border);
}
.navbar .section .container {
  min-height: 52px;
  align-items: center;
}
.navbar .nav-item {
  cursor: pointer;
}
.navbar .nav-item.active:after {
  bottom: -2px;
}
.navbar .nav-item span {
  line-height: 1;
}
.navbar .container {
  display: flex;
  justify-content: initial;
}
.navbar .navbar-brand img {
  height: 28px;
}
.navbar .navbar-brand span {
  color: var(--wc-gray);
  font-size: 18px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
.navbar .nav-link {
  color: var(--wc-text);
}
.navbar a {
  color: var(--wc-text);
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar {
    min-height: 0;
  }
  .navbar .section .container {
    min-height: 0;
  }
  .navbar .navbar-brand span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbar .nav-item {
    min-height: 48px;
  }
  .navbar .nav-item.active:after {
    bottom: 0;
  }
  .navbar .section-sub {
    background: var(--wc-blue);
  }
  .navbar .section-sub .nav-link {
    color: #fff;
  }
  .navbar .section-sub .navbar-collapse.collapse.show {
    height: 100vh;
  }
}

.dropdown-menu {
  top: 48px;
  right: 0;
}

.v-center {
  display: flex;
  align-items: center;
}`],
})
export class ComponentNavComponent implements OnInit, OnDestroy {
    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.iid = setInterval(() => {
            this.ref.detectChanges();
        }, 1000);
    }

    public ngOnDestroy() {
        try {
            clearInterval(this.iid);
        } catch { }
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public sessionTimeout() {
        const s = +new Date();
        const e = +new Date(this.service.auth.session.session_expires_at);

        function formatMicroseconds(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            milliseconds %= 1000;

            let minutes = Math.floor(seconds / 60);
            seconds %= 60;

            let hours = Math.floor(minutes / 60);
            minutes %= 60;

            // return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        return formatMicroseconds(e - s);
    }
}

export default ComponentNavComponent;