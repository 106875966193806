import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.mypage/view.scss */
.container {
  max-width: 720px;
}

.content-page-header {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  padding-top: 0;
}
.content-page-header * {
  margin: 0;
  line-height: 1;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}

.wiz-form .wiz-form-rows:first-child {
  border-top: none;
}

.wiz-form .wiz-form-rows:last-child {
  border-bottom: none;
}`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        this.data.first_name = this.service.auth.session.first_name;
        this.data.last_name = this.service.auth.session.last_name;
        await this.service.render();
        await this.load();
        if (!this.isAdmin()) await this.getRequest();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "Confirm",
            status: status
        });
    }

    public sub = null;

    public async load() {
        const { code, data } = await wiz.call("session");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        const { attributes, hasPassword, sub } = data;
        this.attrs = attributes;
        this.hasPassword = hasPassword;
        this.sub = sub;
        await this.service.render();
    }

    public data = {
        first_name: "",
        last_name: "",
    };
    public async updateProfile() {
        const body = this.service.copy(this.data);
        const { code } = await wiz.call("update_profile", body);
        if (code !== 200) return await this.service.error("Error");
        this.service.success("Success to update profile");
        await this.load();
        await this.service.auth.init();
    }

    public attrs: any = [];
    public async updateAttributes() {
        const body = this.attrs.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
        }, {});
        const { code } = await wiz.call("update_attributes", body);
        if (code !== 200) return await this.service.error("Error");
        this.service.success("Success to update user attributes");
        await this.load();
        await this.service.auth.init();
    }

    public hasPassword = false;
    public password = {
        current: "",
        data: "",
        repeat: "",
    };
    public async changePassword() {
        const pdata = this.service.copy(this.password);
        if (pdata.data !== pdata.repeat) {
            await this.alert("Check password");
            return;
        }

        let pd = {};
        pd.current = this.service.auth.hash(pdata.current);
        pd.data = this.service.auth.hash(pdata.data);

        const { code, data } = await wiz.call("change_password", pd);
        if (code !== 200) return await this.alert(data);
        await this.service.success("Success to change password. Please login again.");

        location.href = "/auth/logout?returnTo=/authenticate";
    }

    public isAdmin() {
        if (this.service.auth.session.role === 'admin') return true;
        return false;
    }

    public request = {};
    public async getRequest() {
        const { code, data } = await wiz.call("request_info");
        if (code !== 200) return;
        this.request = data;
        await this.service.render();
    }

    public async unregister() {
        if (!this.request.purpose) return;
        const { code } = await wiz.call("unregister", this.request);
        if (code !== 200) return await this.service.error("Error");
        this.service.success("회원탈퇴 신청이 완료되었습니다.");
    }
}

export default PageMypageComponent;