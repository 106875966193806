import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.rp.credentials');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-rp-credentials',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.rp.credentials/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}
.btn.btn-icon {
  min-width: 36px;
  min-height: 36px;
}

.align-items-normal {
  align-items: normal;
}

.new-scope-input {
  width: 150px;
  vertical-align: middle;
  display: inline-block;
}

.mr-minus {
  margin-right: -5px;
}

.new-claim-selet {
  width: 120px;
  display: inline-block;
}`],
})
export class PortalKeycloudExternalRpCredentialsComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public authn_methods = [
        { name: "Client secret over HTTP POST authentication", value: "client_secret_post" },
        { name: "Client secret over HTTP basic authentication", value: "client_secret_basic" },
        { name: "Client secret with symmetrically signed JWT assertion", value: "client_secret_jwt" },
        { name: "Asymmetrically signed JWT assertion", value: "private_key_jwt" },
        { name: "No authentication", value: "" },
    ];

    public jwks = "uri";

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.id) return this.back();

        if (this.info.src.auth_method.AccessTokenRequest === null) {
            this.info.src.auth_method.AccessTokenRequest = "";
        }
        if (this.info.src.extra.jwks) this.jwks = "value";
        else this.jwks = "uri";
        this.loaded = true;
        await this.service.render();
    }

    public async update() {
        const body = this.service.copy({
            id: this.info.src.id,
            auth_method: this.info.src.auth_method,
            extra: this.info.src.extra,
        });
        if (!body.auth_method.AccessTokenRequest) body.auth_method.AccessTokenRequest = null;
        if (this.jwks === "uri") delete body.extra.jwks;
        else if (this.jwks === "value") delete body.extra.jwks_uri;

        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC RP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;
        const body = {
            id: this.info.id,
            srcid: this.info.src.id,
        };
        await wiz.call("delete", body);
        this.back();
    }

    public back() {
        this.service.href(this._url(`/external/oidc/rp`));
    }

    public async regenerate() {
        let res = await this.service.alert.show({
            title: 'Regenerate Client Secret',
            message: 'Are you sure you want to regenerate Client Secret?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'regenerate',
            status: 'error'
        });
        if (!res) return;

        const { code, data } = await wiz.call("regenerate", { id: this.info.src.id });
        if (code !== 200) return await this.service.error("SERVER ERROR");
        this.info.src.client_secret = data;
        await this.service.render();
    }
}

export default PortalKeycloudExternalRpCredentialsComponent;