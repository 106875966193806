import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.mgmt.rp.scopes');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-mgmt-rp-scopes',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.mgmt.rp.scopes/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.align-items-normal {
  align-items: normal;
}

.new-scope-input {
  width: 180px;
  vertical-align: middle;
  display: inline-block;
}

.mr-minus {
  margin-right: -5px;
}

.new-claim-selet {
  width: 120px;
  display: inline-block;
}

.table {
  table-layout: fixed;
}
.table th {
  font-size: 14px;
}
.table td {
  align-content: center !important;
}`],
})
export class PortalKeycloudMgmtRpScopesComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        this.scopes = {};
        if (!this.info.src.id) return this.back();

        await this.loadAttributes();
        await this.loadScopes();
        this.makeAttrmaps();
        this.loaded = true;
        await this.service.render();
    }

    public makeAttrmaps() {
        const attrmaps = [];
        Object.entries(this.scopes).forEach(([scope, use]) => {
            if (!use) return;
            attrmaps.push(...this.scopesObj[scope]);
        });
        this.attrmaps = new Array(...new Set(attrmaps)).map(key => {
            const item = this._attrmaps.find(it => it.key === key);
            if (item) return this.service.copy(item);
            return {
                required: false,
                key,
                attribute: key,
                extra: {
                    use_default: true,
                },
            };
        }).sort((a, b) => {
            if (a.key.toUpperCase() > b.key.toUpperCase()) return 1;
            else return -1;
        });
        this._attrmaps = this.service.copy(this.attrmaps);
        this.service.render();
    }

    public scopesList = [];
    public scopesObj = { openid: [], profile: [], email: [], offline_access: [] };
    public scopes: any = {};

    public async loadScopes() {
        const { code, data } = await wiz.call("scopes", { id: this.info.src.id });
        if (code !== 200) return;
        const { scopes, default_scopes, system_attributes } = data;
        this.scopesObj = scopes;
        this.defaultScopes = default_scopes;
        this.system_attributes = system_attributes;
        this.scopesList = Object.keys(this.scopesObj);
        for (let i = 0; i < this.info.src.allowed_scopes.length; i++) {
            const scope = this.info.src.allowed_scopes[i];
            this.scopes[scope] = true;
        }
        await this.service.render();
    }

    public isDefaultScope(scope) {
        if (Object.keys(this.defaultScopes).includes(scope)) return true;
        return false;
    }

    public disableScope(scope) {
        if (this.isAdmin) return false;
        return this.system_attributes.map(it => it.key).includes(scope);
    }

    public newScope = "";
    public addScope() {
        if (this.scopesList.includes(this.newScope)) return;
        this.scopesList.push(this.newScope);
        this.scopesObj[this.newScope] = [];
        this.scopes[this.newScope] = true;
        this.newScope = "";
        this.service.render();
    }
    public removeScope(scope, i) {
        this.scopesList.splice(i, 1);
        delete this.scopesObj[scope];
        delete this.scopes[scope];
        this.service.render();
    }

    public tmpAttr = null;
    public attributes = [];
    public _attrmaps = [];
    public attrmaps = [];
    public async loadAttributes() {
        const { code, data } = await wiz.call("attributes", { key: this.info.key });
        if (code !== 200) return;
        const { attributes, attrmaps } = data;
        this.attributes = attributes;
        this._attrmaps = attrmaps;
        await this.service.render();
    }

    public placeholder() {
        if (this.info.type === "saml") return "urn:oid:0.9.2342.19200300.100.1.3";
        else return "email";
    }

    public async update() {
        this.info.src.allowed_scopes = [];
        for (let key in this.scopes)
            if (this.scopes[key])
                this.info.src.allowed_scopes.push(key)

        this.info.src.scopes_to_claims = {};
        for (let scope in this.scopesObj) {
            this.info.src.scopes_to_claims[scope] = this.scopesObj[scope];
        }

        const body = this.service.copy({
            id: this.info.src.id,
            allowed_scopes: this.info.src.allowed_scopes,
            scopes_to_claims: this.info.src.scopes_to_claims,
            ref: this.info.key,
            attrmaps: this.attrmaps,
        });
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC RP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", { data: JSON.stringify(this.info) });
        this.service.href("/admin/mgmt/oidc/rp");
    }

    public back() {
        this.service.href("/admin/mgmt/oidc/rp");
    }

    public changeExtra(item, key) {
        item.extra[key] = !item.extra[key];
        this.service.render();
    }

    public changeUseDefault(item) {
        if (item.extra.use_default) {
            delete item.extra.access_token;
            delete item.extra.id_token;
            delete item.extra.userinfo;
        }
        else {
            item.extra.access_token = false;
            item.extra.id_token = true;
            item.extra.userinfo = true;
        }
        this.service.render();
    }
}

export default PortalKeycloudMgmtRpScopesComponent;