import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.mgmt');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-mgmt',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.mgmt/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PageAdminMgmtComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/admin/mgmt/saml/idp");
        if (!['saml', 'oidc'].includes(this.TYPE))
            return service.href("/admin/mgmt/saml/idp");

        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';
        if (this.TYPE === "saml") {
            if (!this.CATEGORY)
                return service.href("/admin/mgmt/saml/idp");
            if (!['idp', 'sp'].includes(this.CATEGORY))
                return service.href("/admin/mgmt/saml/idp");
        }
        if (this.TYPE === "oidc") {
            if (!this.CATEGORY)
                return service.href("/admin/mgmt/oidc/idp");
            if (!['op', 'rp'].includes(this.CATEGORY))
                return service.href("/admin/mgmt/oidc/idp");
        }
    }

    public TYPE: string = "";
    public CATEGORY: string = "";

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async ngDoCheck() {
        const preType = this.TYPE;
        const preCategory = this.CATEGORY;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';

        if (preType !== this.TYPE || preCategory !== this.CATEGORY)
            await this.load();
    }

    public title() {
        return this.service.lang.trans(`menu.mgmt.${this.TYPE}.${this.CATEGORY}`);
    }

    public fields() {
        const type = this.TYPE;
        const category = this.CATEGORY;

        if (type === "saml") {
            if (category === "idp") return ["entityid"];
            else return ["entityid"];
        }
        else if (type === "oidc") {
            if (category === "op") return ["client_id", "issuer"];
            else return ["client_id", "allowed_scopes"];
        }
        else {
            return [];
        }
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const body = this.service.copy(this.search);
        body.type = this.TYPE;
        body.category = this.CATEGORY;

        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async click(item: any) {
        let uri = `/admin/mgmt/${this.TYPE}/${this.CATEGORY}/${item.id}/info`;
        this.service.href(uri);
    }

    public async fetchMetadataAll() {
        const { code } = await wiz.call("metadata_refresh_all");
        if (code !== 200) return await this.service.error("ERROR");
        await this.service.success("All metadata refreshed");
    }
}

export default PageAdminMgmtComponent;