import '@angular/compiler';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAdminMgmtItemComponent } from './page.admin.mgmt.item/page.admin.mgmt.item.component';
import { PageAdminSessionComponent } from './page.admin.session/page.admin.session.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalKeycloudAdminScopesComponent } from './portal.keycloud.admin.scopes/portal.keycloud.admin.scopes.component';
import { PortalKeycloudAttributemapComponent } from './portal.keycloud.attributemap/portal.keycloud.attributemap.component';
import { PageAdminLogComponent } from './page.admin.log/page.admin.log.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalKeycloudMgmtOidcRpOwnersComponent } from './portal.keycloud.mgmt.oidc.rp.owners/portal.keycloud.mgmt.oidc.rp.owners.component';
import { PageDiscoveryComponent } from './page.discovery/page.discovery.component';
import { PageAdminUsersRequestComponent } from './page.admin.users.request/page.admin.users.request.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalKeycloudMgmtSamlIdpComponent } from './portal.keycloud.mgmt.saml.idp/portal.keycloud.mgmt.saml.idp.component';
import { PortalKeycloudMgmtRpScopesComponent } from './portal.keycloud.mgmt.rp.scopes/portal.keycloud.mgmt.rp.scopes.component';
import { PageRegisterComponent } from './page.register/page.register.component';
import { PortalKeycloudMgmtOtherInfoComponent } from './portal.keycloud.mgmt.other.info/portal.keycloud.mgmt.other.info.component';
import { PageAdminMgmtComponent } from './page.admin.mgmt/page.admin.mgmt.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentLangComponent } from './component.lang/component.lang.component';
import { PageMgmtComponent } from './page.mgmt/page.mgmt.component';
import { PortalKeycloudMgmtRpTokenComponent } from './portal.keycloud.mgmt.rp.token/portal.keycloud.mgmt.rp.token.component';
import { PortalKeycloudMgmtSamlSpComponent } from './portal.keycloud.mgmt.saml.sp/portal.keycloud.mgmt.saml.sp.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageAdminDiscoveryComponent } from './page.admin.discovery/page.admin.discovery.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageAgreementComponent } from './page.agreement/page.agreement.component';
import { PortalKeycloudAdminSystemSamlComponent } from './portal.keycloud.admin.system.saml/portal.keycloud.admin.system.saml.component';
import { PortalKeycloudMgmtRpCredentialsComponent } from './portal.keycloud.mgmt.rp.credentials/portal.keycloud.mgmt.rp.credentials.component';
import { PortalKeycloudServiceInfoOidcComponent } from './portal.keycloud.service.info.oidc/portal.keycloud.service.info.oidc.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PageAdminDashboardComponent } from './page.admin.dashboard/page.admin.dashboard.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalKeycloudIdpInfoOidcComponent } from './portal.keycloud.idp.info.oidc/portal.keycloud.idp.info.oidc.component';
import { PageLandingComponent } from './page.landing/page.landing.component';
import { PageLinkPasswordComponent } from './page.link.password/page.link.password.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PageMgmtItemComponent } from './page.mgmt.item/page.mgmt.item.component';
import { PortalKeycloudAdminDiscoveryComponent } from './portal.keycloud.admin.discovery/portal.keycloud.admin.discovery.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { PortalKeycloudAdminSystemSamlattrComponent } from './portal.keycloud.admin.system.samlattr/portal.keycloud.admin.system.samlattr.component';
import { PortalKeycloudMgmtOidcRpLogComponent } from './portal.keycloud.mgmt.oidc.rp.log/portal.keycloud.mgmt.oidc.rp.log.component';
import { PortalKeycloudAdminAttributesComponent } from './portal.keycloud.admin.attributes/portal.keycloud.admin.attributes.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageAdminSystemComponent } from './page.admin.system/page.admin.system.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AppComponent,
        PortalSeasonPagenationComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonAlertComponent,
        PageAdminMgmtItemComponent,
        PageAdminSessionComponent,
        ComponentLoadingComponent,
        PortalKeycloudAdminScopesComponent,
        PortalKeycloudAttributemapComponent,
        PageAdminLogComponent,
        PortalSeasonLoadingComponent,
        PortalKeycloudMgmtOidcRpOwnersComponent,
        PageDiscoveryComponent,
        PageAdminUsersRequestComponent,
        LayoutEmptyComponent,
        PortalKeycloudMgmtSamlIdpComponent,
        PortalKeycloudMgmtRpScopesComponent,
        PageRegisterComponent,
        PortalKeycloudMgmtOtherInfoComponent,
        PageAdminMgmtComponent,
        PortalSeasonTabComponent,
        PortalSeasonUiDropdownComponent,
        ComponentLangComponent,
        PageMgmtComponent,
        PortalKeycloudMgmtRpTokenComponent,
        PortalKeycloudMgmtSamlSpComponent,
        PageMypageComponent,
        PageAdminDiscoveryComponent,
        ComponentQuestionComponent,
        PortalSeasonStatusbarComponent,
        PageAgreementComponent,
        PortalKeycloudAdminSystemSamlComponent,
        PortalKeycloudMgmtRpCredentialsComponent,
        PortalKeycloudServiceInfoOidcComponent,
        LayoutAdminComponent,
        PageAdminDashboardComponent,
        PageAdminUsersComponent,
        PortalKeycloudIdpInfoOidcComponent,
        PageLandingComponent,
        PageLinkPasswordComponent,
        PageAuthenticateComponent,
        PortalSeasonViewerTreeComponent,
        PageMainComponent,
        PageMgmtItemComponent,
        PortalKeycloudAdminDiscoveryComponent,
        PortalSeasonLoadingSeasonComponent,
        ComponentPreComponent,
        PortalKeycloudAdminSystemSamlattrComponent,
        PortalKeycloudMgmtOidcRpLogComponent,
        PortalKeycloudAdminAttributesComponent,
        ComponentNavComponent,
        ComponentNavAdminComponent,
        PageAdminSystemComponent,
        LayoutNavbarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'ko',
        }),
        
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}