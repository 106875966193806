import '@angular/compiler';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAdminMgmtItemComponent } from './page.admin.mgmt.item/page.admin.mgmt.item.component';
import { PageAdminSessionComponent } from './page.admin.session/page.admin.session.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalKeycloudAdminScopesComponent } from './portal.keycloud.admin.scopes/portal.keycloud.admin.scopes.component';
import { PortalKeycloudAttributemapComponent } from './portal.keycloud.attributemap/portal.keycloud.attributemap.component';
import { PageAdminLogComponent } from './page.admin.log/page.admin.log.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalKeycloudMgmtOidcRpOwnersComponent } from './portal.keycloud.mgmt.oidc.rp.owners/portal.keycloud.mgmt.oidc.rp.owners.component';
import { PageDiscoveryComponent } from './page.discovery/page.discovery.component';
import { PageAdminUsersRequestComponent } from './page.admin.users.request/page.admin.users.request.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalKeycloudMgmtSamlIdpComponent } from './portal.keycloud.mgmt.saml.idp/portal.keycloud.mgmt.saml.idp.component';
import { PortalKeycloudMgmtRpScopesComponent } from './portal.keycloud.mgmt.rp.scopes/portal.keycloud.mgmt.rp.scopes.component';
import { PageRegisterComponent } from './page.register/page.register.component';
import { PortalKeycloudMgmtOtherInfoComponent } from './portal.keycloud.mgmt.other.info/portal.keycloud.mgmt.other.info.component';
import { PageAdminMgmtComponent } from './page.admin.mgmt/page.admin.mgmt.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentLangComponent } from './component.lang/component.lang.component';
import { PageMgmtComponent } from './page.mgmt/page.mgmt.component';
import { PortalKeycloudMgmtRpTokenComponent } from './portal.keycloud.mgmt.rp.token/portal.keycloud.mgmt.rp.token.component';
import { PortalKeycloudMgmtSamlSpComponent } from './portal.keycloud.mgmt.saml.sp/portal.keycloud.mgmt.saml.sp.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageAdminDiscoveryComponent } from './page.admin.discovery/page.admin.discovery.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageAgreementComponent } from './page.agreement/page.agreement.component';
import { PortalKeycloudAdminSystemSamlComponent } from './portal.keycloud.admin.system.saml/portal.keycloud.admin.system.saml.component';
import { PortalKeycloudMgmtRpCredentialsComponent } from './portal.keycloud.mgmt.rp.credentials/portal.keycloud.mgmt.rp.credentials.component';
import { PortalKeycloudServiceInfoOidcComponent } from './portal.keycloud.service.info.oidc/portal.keycloud.service.info.oidc.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PageAdminDashboardComponent } from './page.admin.dashboard/page.admin.dashboard.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalKeycloudIdpInfoOidcComponent } from './portal.keycloud.idp.info.oidc/portal.keycloud.idp.info.oidc.component';
import { PageLandingComponent } from './page.landing/page.landing.component';
import { PageLinkPasswordComponent } from './page.link.password/page.link.password.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PageMgmtItemComponent } from './page.mgmt.item/page.mgmt.item.component';
import { PortalKeycloudAdminDiscoveryComponent } from './portal.keycloud.admin.discovery/portal.keycloud.admin.discovery.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { PortalKeycloudAdminSystemSamlattrComponent } from './portal.keycloud.admin.system.samlattr/portal.keycloud.admin.system.samlattr.component';
import { PortalKeycloudMgmtOidcRpLogComponent } from './portal.keycloud.mgmt.oidc.rp.log/portal.keycloud.mgmt.oidc.rp.log.component';
import { PortalKeycloudAdminAttributesComponent } from './portal.keycloud.admin.attributes/portal.keycloud.admin.attributes.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageAdminSystemComponent } from './page.admin.system/page.admin.system.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';

const INDEX_PAGE = "landing";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/mgmt/:type/:category/:id/:menu?",
                component: PageAdminMgmtItemComponent,
                "app_id": "page.admin.mgmt.item"
            },
            {
                "path": "admin/session",
                component: PageAdminSessionComponent,
                "app_id": "page.admin.session"
            },
            {
                "path": "admin/log/:type",
                component: PageAdminLogComponent,
                "app_id": "page.admin.log"
            },
            {
                "path": "admin/users/request",
                component: PageAdminUsersRequestComponent,
                "app_id": "page.admin.users.request"
            },
            {
                "path": "admin/mgmt/:type?/:category?",
                component: PageAdminMgmtComponent,
                "app_id": "page.admin.mgmt"
            },
            {
                "path": "admin/discovery",
                component: PageAdminDiscoveryComponent,
                "app_id": "page.admin.discovery"
            },
            {
                "path": "admin/dashboard",
                component: PageAdminDashboardComponent,
                "app_id": "page.admin.dashboard"
            },
            {
                "path": "admin/users/list",
                component: PageAdminUsersComponent,
                "app_id": "page.admin.users"
            },
            {
                "path": "admin/system/:type",
                component: PageAdminSystemComponent,
                "app_id": "page.admin.system"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "discovery",
                component: PageDiscoveryComponent,
                "app_id": "page.discovery"
            },
            {
                "path": "register",
                component: PageRegisterComponent,
                "app_id": "page.register"
            },
            {
                "path": "agreement",
                component: PageAgreementComponent,
                "app_id": "page.agreement"
            },
            {
                "path": "landing",
                component: PageLandingComponent,
                "app_id": "page.landing"
            },
            {
                "path": "link/password",
                component: PageLinkPasswordComponent,
                "app_id": "page.link.password"
            },
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    },
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "mgmt/:type?/:category?",
                component: PageMgmtComponent,
                "app_id": "page.mgmt"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "mgmt/:type/:category/:id/:menu?",
                component: PageMgmtItemComponent,
                "app_id": "page.mgmt.item"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }