import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.users');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-users',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.users/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn-role {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.detail-modal .th {
  width: 150px !important;
  text-transform: none !important;
  overflow-wrap: anywhere;
}`],
})
export class PageAdminUsersComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        status: 'active',
        page: 1,
        text: '',
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load(page: number = 1, status = null) {
        this.search.page = page;
        if (status !== null) this.search.status = status;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async changeAdmin(item) {
        const session = this.service.auth.session;
        if (session.id === item.id) return await this.service.error("Do not change yourself");
        const { role } = item;
        const _from = role === "user" ? "user" : "admin";
        const _to = role === "user" ? "admin" : "user";
        const res = await this.service.alert.show({
            title: `Permission: ${_from} => ${_to}`,
            status: "success",
            message: `Do you want to change the permissions of "${_from}" to "${_to}"?`,
            action: "change",
            actionBtn: "success",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("change_permission", { user_id: item.id, role: _to });
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Success to change permission!");
        await this.load(this.search.page);
    }

    public judge = null;
    public async openJudge(user, type) {
        const { code, data } = await wiz.call("request_info", { user_id: user.id, type });
        if (code !== 200) return await this.service.error("Error");
        this.judge = { user, data, response: "" };
        if (user.request && user.request.response) this.judge.response = user.request.response;
        await this.service.render();
    }

    public async judgement(action) {
        if (this.judge.response.replace(/\s/g, "").length === 0)
            return await this.service.error("응답 메세지를 반드시 입력해주세요.");
        if (this.search.status === 'unregister') {
            const txt = action === "active" ? "승인" : "반려";
            const res = await this.service.alert.show({
                title: `회원탈퇴 승인관리: ${txt}`,
                status: "error",
                message: `정말 "${this.judge.user.email}" 회원의 탈퇴를 ${txt}하시겠습니까?`,
                action: txt,
                actionBtn: "error",
                cancel: "취소",
            });
            if (!res) return;
        }
        if (this.search.status === 'pending' && action === "active" && !this.judge.user.email_verified) {
            const res = await this.service.alert.show({
                title: `회원가입 승인 경고`,
                status: "error",
                message: `"${this.judge.user.email}" 회원은 이메일이 인증되지 않았습니다. 정말 가입을 승인하시겠습니까?`,
                action: "승인",
                actionBtn: "error",
                cancel: "취소",
            });
            if (!res) return;
        }
        const body = {
            request_id: this.judge.data.id,
            user_id: this.judge.user.id,
            action,
            response: this.judge.response,
        };
        const { code } = await wiz.call("judge", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success(`Success`);
        this.judge = null;
        await this.load(this.search.page);
    }

    public async initPassword(user) {
        const res = await this.service.alert.show({
            title: `Initialize password`,
            status: "error",
            message: `Do you want to initialize password?\nThe password is generated randomly and sent to the target's email.\n========\n${user.email}`,
            action: "initialize",
            actionBtn: "error",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            user_id: user.id,
            email: user.email,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("init_password", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Success send to password!");
    }

    public detail = null;
    public async showDetail(user) {
        const { code, data } = await wiz.call("user", { id: user.id });
        if (code !== 200) return await this.service.error("Error");
        const userAttributes = data.filter(it => it.all);
        const systemAttributes = data.filter(it => !it.all);
        this.detail = { ...user, userAttributes, systemAttributes };
        await this.service.render();
    }

    public async saveUserInfo() {
        const { userAttributes, systemAttributes } = this.detail;
        const fn = item => ({ key: item.key, value: item.value });
        const ua = this.service.copy([...userAttributes, ...systemAttributes])
            .filter(it => !(["sub", "preferred_username"].includes(it.key)))
            .map(fn)
            .filter(it => it.value);
        const body = {
            user_id: this.detail.id,
            userAttributes: ua,
        };
        const { code } = await wiz.call("save_user", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Success to save!");
    }

    public async blockUser(user) {
        const res = await this.service.alert.show({
            title: `사용자 차단`,
            status: "error",
            message: `"${user.email}" 사용자를 차단하시겠습니까?`,
            action: "차단",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;
        const body = {
            user_id: user.id,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("block", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Success to block user!");
        await this.load();
    }

    public async clearBlock(user) {
        const res = await this.service.alert.show({
            title: `사용자 차단해제`,
            status: "success",
            message: `차단된 사용자 "${user.email}"의 상태를 다시 활성화하시겠습니까?`,
            action: "차단해제",
            actionBtn: "success",
            cancel: "취소",
        });
        if (!res) return;
        const body = {
            user_id: user.id,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("active", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Success to clear user!");
        await this.load();
    }

    public async clearFailed(user) {
        const res = await this.service.alert.show({
            title: `계정 잠금해제`,
            status: "success",
            message: `잠긴 계정 "${user.email}"에 대해 잠금을 해제하시겠습니까?`,
            action: "잠금해제",
            actionBtn: "success",
            cancel: "취소",
        });
        if (!res) return;
        const body = {
            user_id: user.id,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("clear_failed", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("계정 잠금해제 성공!");
        await this.load();
    }

    public add = {
        show: false,
        mode: "email",
        data: {},
        toggle: () => {
            this.add.data = {
                username: "",
                email: "",
                first_name: "",
                last_name: "",
            };
            this.add.mode = "email";
            this.add.show = !this.add.show;
            this.service.render();
        },
        save: async () => {
            const body = this.service.copy(this.add.data);
            body.mode = this.add.mode;
            const { username, email, first_name, last_name } = body;
            if (!username) return await this.service.error("ID를 입력해주세요.");
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return await this.service.error("이메일 형식이 아닙니다.");
            if (first_name.length < 2) return await this.service.error("이름을 입력해주세요");
            if (last_name.length < 1) return await this.service.error("성을 입력해주세요");

            const message = [
                body.mode === "email"?
                `패스워드 변경 링크가 이메일로 전송됩니다.`
                :
                `패스워드는 랜덤 문자열로 생성되어 표시합니다.`,
                `입력한 정보가 맞는지 확인해주세요.`,
                `----------------------------------`,
                `ID: ${username}`,
                `이메일: ${email}`,
                `이름: ${first_name}`,
                `성: ${last_name}`,
            ].join("\n");
            const res = await this.service.alert.show({
                title: `사용자 추가`,
                status: "success",
                message,
                action: "추가하기",
                actionBtn: "success",
                cancel: "취소",
            });
            if (!res) return;
            await this.service.loading.show();
            const { code, data } = await wiz.call("add_user", body);
            await this.service.loading.hide();
            if (code !== 200) return await this.service.error("ERROR");
            if (body.mode === "email") await this.service.success("사용자 추가 성공");
            else await this.service.success(`아래 패스워드는 팝업을 닫을 시 다시 확인할 수 없습니다.\n반드시 복사해주세요.\n----------------------------------\n${data}`);
            await this.load();
            this.add.toggle();
        },
    };

    public async clearRegister(user) {
        await wiz.call("clear_register", { user_id: user.id });
        await this.load();
    }
}

export default PageAdminUsersComponent;