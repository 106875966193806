import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mgmt');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mgmt',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.mgmt/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PageMgmtComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/mgmt/saml/idp");
        if (!['saml', 'oidc'].includes(this.TYPE))
            return service.href("/mgmt/saml/idp");

        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';
        if (this.TYPE === "saml") {
            if (!this.CATEGORY)
                return service.href("/mgmt/saml/idp");
            if (!['idp', 'sp'].includes(this.CATEGORY))
                return service.href("/mgmt/saml/idp");
        }
        if (this.TYPE === "oidc") {
            if (!this.CATEGORY)
                return service.href("/mgmt/oidc/idp");
            if (!['op', 'rp'].includes(this.CATEGORY))
                return service.href("/mgmt/oidc/idp");
        }
    }

    public TYPE: string = "";
    public CATEGORY: string = "";

    public loaded: boolean = false;
    public list: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        await this.load();
    }

    public async ngDoCheck() {
        const preType = this.TYPE;
        const preCategory = this.CATEGORY;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';

        if (preType !== this.TYPE || preCategory !== this.CATEGORY)
            await this.load();
    }

    public title() {
        return this.service.lang.trans(`menu.mgmt.${this.TYPE}.${this.CATEGORY}`);
    }

    public fields() {
        const type = this.TYPE;
        const category = this.CATEGORY;

        if (type === "saml") {
            if (category === "idp") return [];
            else return [];
        }
        else if (type === "oidc") {
            if (category === "op") return ["client_id", "issuer"];
            else return ["client_id", "allowed_scopes"];
        }
        else {
            return [];
        }
    }

    public async load() {
        const body = {
            type: this.TYPE,
            category: this.CATEGORY,
        }
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", body);
        if (code != 200) return;
        this.list = data;
        this.loaded = true;
        await this.service.render();
    }

    public async click(item: any) {
        let uri = `/mgmt/${this.TYPE}/${this.CATEGORY}/${item.id}/info`;
        this.service.href(uri);
    }
}

export default PageMgmtComponent;