import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.log');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-log',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.log/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PageAdminLogComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/admin/log/useraccess");
        if (!['useraccess', 'consent', 'admin', 'request'].includes(this.TYPE))
            return service.href("/admin/log/useraccess");
    }

    public TYPE: string = '';

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public title() {
        if (this.TYPE === 'useraccess') return "User Access Log";
        if (this.TYPE === 'consent') return "User Consent Log";
        if (this.TYPE === 'request') return "User Request Log";
        if (this.TYPE === 'admin') return "Admin Activity Log";
        return "";
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async ngDoCheck() {
        let preType = this.TYPE;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return this.service.href("/admin/log/useraccess");
        if (preType != this.TYPE)
            await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.search.type = this.TYPE;
        if (!this.search.type) return;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public displayUser(user) {
        if (!user) return "-";
        return `${user.first_name} ${user.last_name} (${user.email})`;
    }
}

export default PageAdminLogComponent;