import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.session');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-session',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.session/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PageAdminSessionComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public mode = "access_token";
    public changeMode(mode) {
        this.mode = mode;
        this.load();
    }

    public list: any = [];
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.list = [];
        await this.service.render();
        const body = this.service.copy(this.search);
        body.mode = this.mode;
        const { code, data } = await wiz.call("load", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async delete(id) {
        const res = await this.service.alert.show({
            title: "Remove session",
            message: "Really remove this session?",
            status: "error",
            actionBtn: "error",
            action: "remove",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("delete", { id });
        if (code !== 200) return await this.service.error("Failed to remove this session");
        await this.service.success("success to remove session.");
        await this.load();
    }
}

export default PageAdminSessionComponent;