import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.authenticate');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-authenticate',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.authenticate/view.scss */
.wrapper {
  background-image: url(/assets/images/login.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container.half {
  max-width: 480px;
}

.wrapper-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.circle {
  background-color: #fff;
  border: 3px solid var(--wc-blue);
  width: 140px;
  height: 140px;
  top: -90px;
  left: calc(50% - 70px);
  border-radius: 70%;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
  z-index: 5;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle .logo {
  width: 100%;
  max-width: 80px;
  margin: auto;
}
.circle .logo > a {
  width: 80px;
}
.circle .logo img {
  width: 100%;
}

.form {
  padding: 20px 0;
  z-index: 2;
  color: #000000;
  background-color: white;
  border-radius: 16px;
  position: relative;
  padding-bottom: 52px;
}
.form .box-login {
  display: flex;
}
.form input {
  border: 1px solid #000;
}
.form input:disabled {
  border-right-color: #000;
  border-left-color: #000;
  color: var(--wc-gray);
  text-align: center;
}
.form input:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-color: #000;
  font-family: "main-r";
}
.form input:not(:last-child) {
  margin-right: -1px;
}
.form input:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-color: #000;
  font-family: "main-r";
}

.wrapper .form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 15vh 0;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 768px) {
  .container {
    padding: 10vh 16px;
  }
}

.box-login {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
}
@media (max-width: 768px) {
  .box-login {
    width: 85%;
  }
}

.form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-width: 1px;
  background-color: rgb(255, 255, 255);
  display: block;
  font-size: 18px;
  color: #2b2929;
  transition-duration: 0.25s;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  box-sizing: border-box;
}

.form input:hover {
  background-color: rgb(255, 255, 255);
}

.form input:focus {
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: #000000;
  border-color: #6d8df6;
}

.form input::placeholder {
  color: #ddd;
}

.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  transition-duration: 0.25s;
  position: relative;
  width: 70%;
  margin: auto;
  padding: 18px 19px 19px;
  box-sizing: border-box;
  font-family: "main-b";
}
@media (max-width: 768px) {
  .form button {
    width: 80%;
  }
}

.addition-info {
  width: 70%;
  margin: auto;
  font-size: 14px;
  display: block;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .addition-info {
    width: 80%;
  }
}
.addition-info a {
  color: var(--wc-text);
  cursor: pointer;
}
.addition-info a:hover {
  color: #6380de;
}

.form button:hover {
  color: var(--wc-blue);
  border: 1px solid var(--wc-blue);
  background-color: rgba(108, 141, 246, 0.2);
}

.hint {
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  color: var(--wc-text);
  background-color: var(--wc-light);
}

.single-sign-on {
  text-align: center;
}
.single-sign-on .overflow-y-auto {
  max-height: 300px;
}
.single-sign-on .card {
  max-width: 320px;
  margin: 12px auto;
  cursor: pointer;
}

.rounded-start,
.rounded-end {
  --tblr-border-radius: 10px;
}

.login-box {
  margin-top: 40px;
}
.login-box input {
  padding: 18px 19px;
}`],
})
export class PageAuthenticateComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public SAMLRequest = null;
    public token = null;
    public returnTo: string = "notset";
    public federations = [];

    public async ngOnInit() {
        await this.service.init();
        const params = new URLSearchParams(document.location.search);

        // is oidc
        this.token = params.get("token");
        let returnTo = params.get("returnTo") || "/mypage";
        if (this.token) returnTo = null;

        // is saml
        this.SAMLRequest = params.get("SAMLRequest");

        let check = await this.service.auth.allow(false, returnTo);
        if (check) this.returnTo = returnTo;
        else if (this.token) {
            location.href = `/op/verify/user${location.search}`;
            return;
        }
        await this.getProviders();
    }

    public idp = {
        common: [],
        social: [],
    };
    public async getProviders() {
        const { code, data } = await wiz.call("providers");
        if (code !== 200) return;
        if (Array.isArray(data)) {
            this.idp.common = data;
            this.idp.social = [];
        }
        else {
            const { common, social } = data;
            this.idp.common = common;
            this.idp.social = social;
        }
        await this.service.render();
    }

    public view: string = 'login.email';

    public data: any = {
        email: '',
        password: '',
        first_name: "",
        last_name: "",
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async toLogin() {
        this.view = 'login.email';
        this.data = {
            email: '',
            password: '',
            first_name: "",
            last_name: "",
        };
        await this.service.render();
    }

    public async login() {
        let user = this.service.copy(this.data);
        if (user.password)
            user.password = this.service.auth.hash(user.password);
        else
            delete user.password;

        if (!user.username) return;
        let { code, data } = await wiz.call("login", user);

        if (code == 200) {
            if (this.token) {
                location.href = `/op/verify/user${location.search}`;
                return;
            }
            else if (this.SAMLRequest) {
                location.href = "/idp/sso/response";
                return;
            }
            location.href = this.returnTo;
            return;
        }
        else if (code == 201) {
            this.view = "login.password";
            await this.service.render();
            try {
                setTimeout(() => {
                    document.querySelector("input[type=password]").focus();
                }, 150);
            } catch { }
        }
        else if (code == 301) {
            let isjoin = await this.alert(data, 'success', '닫기', '계정 만들기');
            if (!isjoin) return;
            this.view = "join.terms";
            await this.service.render();
        }
        else {
            await this.alert(data, 'error');
        }
    }

    public async join_start() {
        this.view = "join.userrequired";
        this.verify.email = false;
        this.verify.email_check = undefined;
        this.captchaRefresh();
        await this.service.render();
    }

    public async emailAuthentication(email: string, showstatus: boolean = true) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return await this.service.error("이메일 형식이 아닙니다.");
        this.verify.email = false;
        this.verify.email_check = email;
        await this.service.loading.show();
        let res = await wiz.call('emailAuthentication', { email });
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            await this.alert('이메일 인증코드가 발송되었습니다', 'success');
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public verify = {
        email: false,
        email_check: undefined,
    };
    public async emailVerify(email: string, onetimepass: string) {
        await this.service.loading.show();
        let res = await wiz.call('emailVerify', { email, onetimepass });
        await this.service.loading.hide();
        if (res.code == 200) {
            await this.service.success("이메일이 인증되었습니다.");
            this.verify.email = true;
            await this.service.render();
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public async join() {
        if (this.data.first_name.length < 2) return await this.alert("이름을 입력해주세요");
        if (this.data.last_name.length < 2) return await this.alert("성을 입력해주세요");

        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        if (!this.data.purpose) return await this.service.error("가입목적을 입력해주세요.");

        let user = this.service.copy(this.data);
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        delete user.onetimepass;
        if (!user.username) return await this.service.error("ID를 입력해주세요.");

        let { code, data } = await wiz.call("join", user);

        if (code == 200) {
            await this.alert("회원가입 신청이 완료되었습니다.\n입력한 이메일로 24시간동안 유효한 인증 메일을 전송하였습니다.\n이메일 인증 및 관리자의 승인 후 로그인할 수 있습니다.", "success");
            location.reload();
        } else {
            await this.alert(data);
        }
    }

    public async sso(idp) {
        if (idp.type === "oidc") {
            const body = { key: idp.key };
            if (this.token) body.token = this.token;
            const { code, data } = await wiz.call("login_uri", body);
            if (code !== 200) return await this.service.error("SERVER ERROR");
            return location.href = data;
        }
        else { // saml
            if (idp.federation) { // federation
                const body = this.service.copy(idp);
                if (this.token) body.token = this.token;
                const { code, data } = await wiz.call("discovery", body);
                if (code !== 200) return await this.service.error("SERVER ERROR");
                const params = new URLSearchParams(data);
                return this.service.href(`/discovery?${params.toString()}`);
            }
            let params = {
                key: idp.key,
                authnContextRef: "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
            };
            if (idp.entityid) params.entityid = idp.entityid;
            if (this.token) params.token = this.token;
            params = new URLSearchParams(params);
            return location.href = `/sp/login?${params.toString()}`;
        }
    }

    public captcha = null;
    public async captchaRefresh() {
        this.captcha = null;
        await this.service.render(100);
        this.captcha = this.captchaURL();
        await this.service.render();
    }
    public captchaURL() {
        return wiz.url(`captcha?ts=${+new Date()}`);
    }

    public async toPasswordReset() {
        this.view = 'login.password.reset';
        this.data = {
            email: '',
            password: '',
        };
        await this.service.render();
    }
    public async emailAuthenticationPassword(email: string, showstatus: boolean = true) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return await this.service.error("이메일 형식이 아닙니다.");
        this.verify.email = false;
        this.verify.email_check = email;
        await this.service.loading.show();
        const body = {
            type: "reset",
            username: this.data.username,
            email,
        };
        let res = await wiz.call('emailAuthentication', body);
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            this.verify.key = res.data;
            await this.alert('이메일 인증코드가 발송되었습니다', 'success');
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public async emailVerifyPassword(email: string, onetimepass: string) {
        await this.service.loading.show();
        if (!email) return;
        if (!onetimepass) return;
        if (!this.data.username) return;
        const body = {
            type: "reset",
            username: this.data.username,
            email,
            onetimepass,
            key: this.verify.key,
        };
        let res = await wiz.call('emailVerify', body);
        await this.service.loading.hide();
        if (res.code == 200) {
            await this.service.success("이메일로 임의의 값으로 생성한 패스워드가 발송되었습니다.\n로그인 후 반드시 패스워드를 변경해주세요.");
            return location.reload();
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public idptext = "";
    public filter(name) {
        return name.toLowerCase().includes(this.idptext.toLowerCase());
    }
}

export default PageAuthenticateComponent;