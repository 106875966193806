import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.internal.oidc');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-internal-oidc',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.internal.oidc/view.scss */
.content > .d-flex {
  line-height: 44px;
}
.content > .d-flex .th {
  min-width: 200px;
  padding: 0 16px 0 8px;
  align-content: center;
}
.content > .d-flex .th:has(+ .td) {
  background: ghostwhite;
  border: 1px solid lightgray;
  border-right: 0;
}
.content > .d-flex .th.border-top {
  border-top: 1px solid lightgray !important;
}
.content > .d-flex .td {
  flex: 1;
}
.content > .d-flex .td.border {
  border: 1px solid #dbdfe5 !important;
}
.content > .d-flex .td.attributes > * {
  display: inline-block;
  vertical-align: middle;
}
.content > .d-flex .td .btn-close {
  width: 18px;
  height: 18px;
  padding: 0;
}
.content > .d-flex .td .btn-close:hover, .content > .d-flex .td .btn-close:focus {
  background-color: transparent !important;
}
.content > .d-flex input[type=file] {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 44px;
  max-width: 350px;
}
.content input:focus,
.content button:hover {
  position: relative;
}
.content > .d-flex + .d-flex {
  margin-top: -1px;
}

.mt--1 {
  margin-top: -1px;
}

.mb--1 {
  margin-bottom: -1px;
}

.ml--1 {
  margin-left: -1px;
}

.mr--1 {
  margin-right: -1px;
}

.b-1 {
  border: 1px solid lightgray !important;
}

.bt-1 {
  border-top: 1px solid lightgray !important;
}

.bb-1 {
  border-bottom: 1px solid lightgray !important;
}

.br-1 {
  border-right: 1px solid lightgray !important;
}

.bl-1 {
  border-left: 1px solid lightgray !important;
}

wiz-component-pre {
  line-height: normal;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudAdminInternalOidcComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public cert = {
        op: "",
        rp: "",
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/mypage");
        await this.load();
        this.cert.op = await this.getCert("op");
        this.cert.rp = await this.getCert("rp");
        await this.service.render();
    }

    public origin() {
        return location.origin;
    }

    public op = {};
    public rp = {};

    public async getCert(type) {
        const { code, data } = await wiz.call("get_cert", { type });
        if (code !== 200) return "";
        let res = data;
        try {
            res = JSON.stringify(JSON.parse(data), null, 2);
        } catch { }
        return res;
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        Object.entries(data).forEach(([key, value]) => {
            if (key.startsWith("OP_")) {
                const k = key.slice(3).toLowerCase();
                this.op[k] = value;
            }
            else if (key.startsWith("RP_")) {
                const k = key.slice(3).toLowerCase();
                this.rp[k] = value;
            }
        });
        await this.service.render();
    }

    public async save() {
        const body = [];
        Object.entries(this.idp).forEach(([key, value]) => {
            body.push([`OP_${key.toUpperCase()}`, value]);
        });
        Object.entries(this.sp).forEach(([key, value]) => {
            body.push([`RP_${key.toUpperCase()}`, value]);
        });
        await this.service.loading.show();
        const { code } = await wiz.call("save", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }

    public modal = null;
    public showModal(act) {
        this.modal = act;
        this.service.render();
    }
}

export default PortalKeycloudAdminInternalOidcComponent;