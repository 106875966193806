import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.lang');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-lang',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.lang/view.scss */
select.form-select {
  position: fixed;
  width: 90px;
  z-index: 4010;
}
select.form-select.top-right {
  top: 10px;
  right: 20px;
}
select.form-select.top-left {
  top: 10px;
  left: 20px;
}
select.form-select.bottom-right {
  bottom: 10px;
  right: 20px;
}
select.form-select.bottom-left {
  bottom: 10px;
  left: 20px;
}
select.form-select.top {
  top: 10px;
}
select.form-select.left {
  left: 20px;
}
select.form-select.right {
  right: 20px;
}
select.form-select.bottom {
  bottom: 10px;
}`],
})
export class ComponentLangComponent implements OnInit {
    @Input() position = "top right";
    public lang = "ko";

    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
        this.lang = this.service.lang.value;
        await this.service.render();
    }
}

export default ComponentLangComponent;